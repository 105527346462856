<section class="globalContainer darkBlueBackground pb-3">
  <app-header-family-club></app-header-family-club>
  <form
    [formGroup]="registerForm"
    class="px-5 formContainer"
    (ngSubmit)="onRegisterFormSubmit()"
  >
    <h5 class="colorWhite mt-3 mb-4">{{ "REGISTER.TITLE" | translate }}</h5>
    <div class="form-group" *ngIf="!newReg">
      <!-- mobile number -->
      <img class="fieldImg" src="../../../assets/images/smartphone.png" />
      <input
        type="number"
        class="form-control"
        id="mobileNumber"
        placeholder="{{ 'REGISTER.PLACEHOLDER3' | translate }}"
        formControlName="mobileNumber"
        (change)="checkSalesForce($event)"
        [ngClass]="{
          'is-invalid': submitted && regFormVal.mobileNumber.errors
        }"
      />
      <div
        *ngIf="submitted && regFormVal.mobileNumber.errors"
        class="invalid-feedback"
      >
        <div *ngIf="regFormVal.mobileNumber.errors.required">
          Enter mobilenumber
        </div>
        <div
          class="text-left"
          *ngIf="
            regFormVal.mobileNumber.errors.pattern ||
            regFormVal.mobileNumber.errors.pattern
          "
        >
          Invalid Mobile Number
        </div>
      </div>
    </div>
    <div class="form-group">
      <!-- name -->
      <img class="fieldImg" src="../../../assets/images/avatar.png" />
      <input
        type="text"
        appAlphabetOnly
        class="form-control"
        appAlphabetOnly
        id="name"
        aria-describedby="emailHelp"
        placeholder="{{ 'REGISTER.PLACEHOLDER1' | translate }}"
        formControlName="name"
        [ngClass]="{ 'is-invalid': submitted && regFormVal.name.errors }"
      />

      <div *ngIf="submitted && regFormVal.name.errors" class="invalid-feedback">
        <div *ngIf="regFormVal.name.errors.required">Enter name</div>
      </div>
    </div>
    <div class="form-group">
      <!-- surname -->
      <img class="fieldImg" src="../../../assets/images/avatar.png" />

      <input
        type="text"
        appAlphabetOnly
        class="form-control"
        id="surname"
        placeholder="{{ 'REGISTER.PLACEHOLDER2' | translate }}"
        formControlName="surname"
        [ngClass]="{ 'is-invalid': submitted && regFormVal.surname.errors }"
      />
      <div
        *ngIf="submitted && regFormVal.surname.errors"
        class="invalid-feedback"
      >
        <div *ngIf="regFormVal.surname.errors.required">Enter surname</div>
      </div>
    </div>
    <div class="form-group">
      <img
        class="fieldImg fieldImgSecondary"
        src="../../../assets/images/message.png"
      />
      <input
        type="email"
        class="form-control"
        id="email"
        placeholder="{{ 'REGISTER.PLACEHOLDER4' | translate }}"
        formControlName="email"
        [ngClass]="{ 'is-invalid': submitted && regFormVal.email.errors }"
        (input)="emailCheck($event)"
      />
      <div
        *ngIf="submitted && regFormVal.email.errors && !emailExsistError"
        class="invalid-feedback"
      >
        <!-- <div *ngIf="regFormVal.email.errors.required">Enter email</div> -->
        <div *ngIf="regFormVal.email.errors.pattern">Invalid email format</div>
      </div>
      <div *ngIf="emailExsistError" class="text-danger pl-0 textsize">
        <div>มีอีเมลนี้อยู่แล้ว โปรดใช้รหัสอีเมลอื่น</div>
      </div>
    </div>
    <div class="form-group">
      <img class="fieldImg" src="../../../assets/images/qricon1.png" />
      <input
        type="text"
        [readOnly]="isReferralCodeAvailable"
        class="form-control"
        id="referrerCode"
        placeholder="{{ 'REGISTER.PLACEHOLDERQR' | translate }}"
        formControlName="referrerCode"
        maxlength="7"
        minlength="4"
        (change)="checkReferel($event)"
        (keypress)="onlyNumbers($event)"
      />
    </div>
    <div class="form-group" *ngIf="!newReg">
      <!-- password -->
      <img class="fieldImg" src="../../../assets/images/padlock.png" />
      <input
        type="password"
        class="form-control"
        id="password"
        placeholder="{{ 'REGISTER.PLACEHOLDER5' | translate }}"
        formControlName="password"
        [ngClass]="{ 'is-invalid': submitted && regFormVal.password.errors }"
      />
      <div
        *ngIf="submitted && regFormVal.password.errors"
        class="invalid-feedback"
      >
        <div *ngIf="regFormVal.password.errors.required">Enter password</div>
      </div>
    </div>
    <div class="form-group" *ngIf="!newReg">
      <!-- confirm passwordS -->
      <img class="fieldImg" src="../../../assets/images/padlock.png" />
      <input
        type="password"
        class="form-control"
        id="confirmPassword"
        placeholder="{{ 'REGISTER.PLACEHOLDER6' | translate }}"
        formControlName="confirmPassword"
        [ngClass]="{
          'is-invalid': submitted && regFormVal.confirmPassword.errors
        }"
      />
      <div
        *ngIf="submitted && regFormVal.confirmPassword.errors"
        class="invalid-feedback"
      >
        <div *ngIf="regFormVal.confirmPassword.errors.required">
          This section is required
        </div>
        <div *ngIf="regFormVal.confirmPassword.errors.mustMatch">
          Password confirmation does not match
        </div>
      </div>
    </div>
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id=""
        formControlName="acceptTerms"
        [ngClass]="{ 'is-invalid': submitted && regFormVal.acceptTerms.errors }"
      />
      <label
        class="form-check-label colorWhite fontKanitLight"
        for="exampleCheck1"
        >{{ "REGISTER.CHECK_TXT_LEFT" | translate }}
        <span
          class="underlinedText"
          (click)="capture()"
          [routerLink]="['/terms']"
        >
          {{ "REGISTER.CHECK_TEXT_RIGHT" | translate }}</span
        ></label
      >
      <div
        *ngIf="submitted && regFormVal.acceptTerms.errors"
        class="invalid-feedback"
      >
        <div *ngIf="regFormVal.acceptTerms.errors.required">
          กรุณากดยอมรับเพื่อสมัครใช้งาน
        </div>
      </div>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id=""
        formControlName="agreePolicy"
        [ngClass]="{ 'is-invalid': submitted && regFormVal.agreePolicy.errors }"
      />
      <label
        class="form-check-label colorWhite fontKanitLight"
        for="exampleCheck1"
        >{{ "REGISTER.CHECK_TXT_LEFT1" | translate }}
        <span
          class="underlinedText"
          (click)="capture()"
          [routerLink]="['/conditions']"
        >
          {{ "REGISTER.CHECK_TEXT_RIGHT1" | translate }}</span
        ></label
      >
      <div
        *ngIf="submitted && regFormVal.agreePolicy.errors"
        class="invalid-feedback"
      >
        <div *ngIf="regFormVal.agreePolicy.errors.required">
          กรุณากดยอมรับเพื่อสมัครใช้งาน
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-block goldenBtn mt30 mb25">
      {{ "REGISTER.BTN_TEXT" | translate }}
    </button>
  </form>
</section>

<div
  bsModal
  #regOtpModal="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body px-2">
        <form [formGroup]="registerOtpForm">
          <section class="text-center">
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="closeRegOtpModal()"
            >
              <!-- <span aria-hidden="true">&times;</span> -->
              <img src="../../../assets/images/close.png" />
            </button>
            <h5 class="primaryBlue font-weight-bold">
              {{ "REG_OTP_MODAL.TITLE1" | translate }}
            </h5>
            <h5 class="secondaryBlue mt30">
              {{ "REG_OTP_MODAL.TITLE2" | translate }}
            </h5>
            <!-- <h5>{{ 'REG_OTP_MODAL.TITLE3' | translate }}</h5> -->
            <h5>{{ entermobNowithzero }}</h5>

            <!-- <h5 class="darkBrown mt20 text-uppercase">Ref code : {{ 'REG_OTP_MODAL.REF_CODE' | translate }}</h5> -->

            <h5 class="secondaryBlue mt30 mb-4 font-weight-bold">
              {{ "REG_OTP_MODAL.TITLE4" | translate }}
            </h5>
            <div class="row justify-content-center">
              <div class="col-10">
                <ng-otp-input
                  (onInputChange)="onOtpChange($event)"
                  [config]="{
                    length: 6,
                    placeholder: '0',
                    allowNumbersOnly: true
                  }"
                  [ngClass]="{
                    'is-invalid':
                      (submitRegOtpForm &&
                        (otp == undefined || otp.length < 6)) ||
                      invalidOtp
                  }"
                  #ngOtpInput
                >
                </ng-otp-input>
                <!-- <div *ngIf="submitRegOtpForm && regOtpFormVal.otp.errors" class="text-danger pl-0 mt-2">
                                    <div *ngIf="!activeClassButton">{{ 'REG_OTP_MODAL.OTP_ERROR' | translate }}</div>
                                </div> -->
                <!-- <div *ngIf="invalidOtp" class="text-danger pl-0">
                                    <div>The entered OTP is incorrect</div>
                                </div> -->
                <div
                  *ngIf="submitRegOtpForm && regOtpFormVal.otp.errors.required"
                  class="text-danger pl-0 mt-2"
                >
                  <div *ngIf="!activeClassButton">ต้องระบุ otp</div>
                </div>
                <div *ngIf="invalidOtp" class="text-danger pl-0">
                  <div>{{ "REG_OTP_MODAL.OTP_ERROR" | translate }}</div>
                </div>

                <p *ngIf="!showResendOtp" class="colorGrey mt-4">
                  {{ "REG_OTP_MODAL.LINK_TEXT_LEFT" | translate }}
                  <span class="primaryBlue font-weight-bold" *ngIf="beginTimer">
                    <countdown
                      #cd
                      [config]="{ leftTime: 60, format: 'ss', notify: [2, 5] }"
                      (event)="handleEvent($event)"
                    ></countdown>
                    {{ "REG_OTP_MODAL.LINK_TEXT_RIGHT" | translate }}</span
                  >
                </p>

                <p
                  *ngIf="showResendOtp"
                  class="mt-4 underlinedText lightBlue"
                  (click)="resendOTP()"
                  (click)="cd.restart()"
                >
                  {{ "FORGOT_OTP_MODAL.LINK_TEXT" | translate }}
                </p>

                <button
                  type="submit"
                  class="btn btn-block goldenBtn my-4"
                  (click)="onSubmitRegOtpForm()"
                >
                  {{ "REG_OTP_MODAL.BTN_TEXT" | translate }}
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- success scan modal -->
<div
  bsModal
  #successScanModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeSuccessScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>{{ "SUCCESS_SCAN_MODAL.SCANNED_SUCCESSFULLY" | translate }}</h4>
        <p>
          {{ "SUCCESS_SCAN_MODAL.CONGRATULATIONS_YOU_RECEIVED" | translate }}
          {{ isprintPoint }} {{ "SUCCESS_SCAN_MODAL.SCORE" | translate }}
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeSuccessScanModal()"
        >
          {{ "SUCCESS_SCAN_MODAL.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- GPAS success scan modal -->
<div
  bsModal
  #gpasSuccessMdl="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeGpasSuccessScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>สแกนสำเร็จ</h4>
        <p>
          คุณจะได้รับ {{ gpasPoints }} คะแนน <br />
          ระบบจะตรวจสอบและอัปเดตคะแนนภายใน 7 วัน <br />
          กรุณาเก็บใบเสร็จเพื่อเป็นหลักฐาน
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeGpasSuccessScanModal()"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>

<!-- failed scan modal -->
<div
  bsModal
  #failedScanModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeFailedScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>{{ "FAILED_SCAN_MODAL.SCAN_FAILED" | translate }}</h4>
        <p>{{ "FAILED_SCAN_MODAL.QR_CODE_ALREADY_IN_USE" | translate }}</p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeFailedScanModal()"
        >
          {{ "FAILED_SCAN_MODAL.TRY_AGAIN" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- exceed limit modal -->
<div
  bsModal
  #exceedLimitModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeExceedLimitModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button> -->
        <h4>ขออภัย</h4>
        <p
          *ngIf="
            catCode == 'Pediasure' ||
            catCode == 'PEDIASURE' ||
            catCode == 'pediasure'
          "
        >
          คุณสแกนผลิตภัณฑ์พีเดียชัวร์ ครบจำนวน 5 ครั้งสำหรับเดือนนี้แล้ว
          กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่ 02-252-2448
        </p>
        <p
          *ngIf="
            catCode == 'Ensure' || catCode == 'ENSURE' || catCode == 'ensure'
          "
        >
          คุณสแกนผลิตภัณฑ์เอนชัวร์ ครบจำนวน 8 ครั้ง สำหรับเดือนนี้แล้ว
          กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่ 02-252-2448
        </p>
        <p
          *ngIf="
            catCode == 'Glucerna' ||
            catCode == 'GLUCERNA' ||
            catCode == 'glucerna'
          "
        >
          คุณสแกนผลิตภัณฑ์กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์ ครบจำนวน 5 ครั้ง
          สำหรับเดือนนี้แล้ว กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่
          02-252-2448
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeExceedLimitModal()"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
