<div class="globalContainer _100vh" [hidden]="showScanner">
  <app-header-with-abbott></app-header-with-abbott>
  <div class="qrScanCodeOut">
    <h4>
      {{ "HOME.HELLO" | translate }}
      <span>{{ user?.firstname }} {{ user?.lastname }}</span>
    </h4>
    <p *ngIf="userPoints">
      {{ "HOME.DO_YOU_HAVE" | translate }} {{ userPoints }}
      {{ "HOME.SCORE" | translate }}
    </p>

    <div class="scannerBtn" (click)="onclickScanToCollect()">
      <i><img src="../../assets/images/camera.png" alt="camera" /></i>
      <p>{{ "HOME.TAP_TO_SCAN_THE_QR" | translate }}</p>
    </div>
  </div>
  <p class="participateProd">{{ "HOME.PARTICIPATING_PRODUCTS" | translate }}</p>
  <div>
    <div class="row text-center product-tabs">
      <div
        [class.ensure-tab]="tabId === 'ensure-tab'"
        class="col-3 tabs"
        id="ensure-tab"
        (click)="ontabChange('ensure-tab')"
      >
        <p>เอนชัวร์</p>
      </div>
      <div
        [class.glucerna-tab]="tabId === 'glucerna-tab'"
        class="col-3 tabglucerna"
        id="glucerna-tab"
        (click)="ontabChange('glucerna-tab')"
      >
        <p>กลูเซอนา</p>
      </div>
      <div
        [class.pediasure-tab]="tabId === 'pediasure-tab'"
        class="col-3 tabs"
        id="pediasure-tab"
        (click)="ontabChange('pediasure-tab')"
      >
        <p>พีเดียชัวร์</p>
      </div>
    </div>
    <div *ngIf="tabId === 'ensure-tab'">
      <div class="homeCarousel">
        <ul class="mt-1 ensureTab">
          <!-- 400 grams products start here  -->
          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en1.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 50 คะแนน</h4>
            <p>
              เอนชัวร์ โกลด์ 400 กรัม <br />
              ทุกรสชาติ
              
            </p>
          </li>
          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en2.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 110 คะแนน</h4>
            <p>
              เอนชัวร์ โกลด์ 850 กรัม  <br />
              ทุกรสชาติ
              
            </p>
          </li>

          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en3.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 150 คะแนน</h4>
            <p>
              เอนชัวร์ โกลด์ 1,200 กรัม  <br />
              กลิ่นวานิลลา และกลิ่นธัญพืช
              

            </p>
          </li>
          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en4.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 200 คะแนน</h4>
            <p>
              เอนชัวร์ โกลด์ 1,600 กรัม  <br />
              กลิ่นวานิลลา และกลิ่นธัญพืช
              
            </p>
          </li>
          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en5.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 300 คะแนน</h4>
            <p>
              เอนชัวร์ โกลด์ 2,400 กรัม  <br />
              กลิ่นวานิลลา และกลิ่นธัญพืช
              
            </p>
          </li>
          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en6.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 50 คะแนน</h4>
            <p>
              เอนชัวร์ 400 กรัม  <br />
              ทุกรสชาติ
              
            </p>
          </li>

          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en7.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 110 คะแนน</h4>
            <p>
              อนชัวร์ 850 กรัม  <br />
              ทุกรสชาติ
             
            </p>
          </li>
          <li>
            <figure>
              <img
                src="../../assets/images/ensure/new/en8.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 300 คะแนน</h4>
            <p>
              เอนชัวร์ 2,400 กรัม  <br />
              กลิ่นวานิลลา และกลิ่นธัญพืช
              
            </p>
          </li>
         </ul>
      </div>
    </div>
    <div *ngIf="tabId === 'glucerna-tab'">
      <div class="homeCarousel">
        <ul class="mt-2">
          <li>
            <figure>
              <img src="../../assets/images/glucerna/new/gl1.png" alt="PDC" />
            </figure>
            <h4>รับ 60 คะแนน</h4>
            <p>
              กลูเซอนา พลัส กลิ่นธัญพืช <br />
              400 กรัม 
              
            </p>
          </li>
          <li>
            <figure>
              <img
                src="../../assets/images/glucerna/new/gl2.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 130 คะแนน</h4>
            <p>
              กลูเซอนา พลัส กลิ่นธัญพืช <br />
              850 กรัม 
              
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/glucerna/new/gl3.png" alt="PDC" />
            </figure>
            <h4>รับ 60 คะแนน</h4>
            <p>
              กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์<br />
              กลิ่นวานิลลา 400 กรัม 
              
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/glucerna/new/gl4.png" alt="PDC" />
            </figure>
            <h4>รับ 130 คะแนน</h4>
            <p>
              กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์<br />
กลิ่นวานิลลา 850 กรัม 

            </p>
          </li>
          <li>
            <figure>
              <img
                src="../../assets/images/glucerna/new/gl5.png"
                alt="PDC"
              />
            </figure>
            <h4>รับ 180 คะแนน</h4>
            <p>
              กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์<br />
              กลิ่นวานิลลา 1,200 กรัม 
              
            </p>
          </li>

          <li>
            <figure>
              <img src="../../assets/images/glucerna/new/gl6.png" alt="PDC" />
            </figure>
            <h4>รับ 60 คะแนน</h4>
            <p>
              กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์<br />
              กลิ่นวานิลลา 400 กรัม 
              
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/glucerna/new/gl7.png" alt="PDC" />
            </figure>
            <h4>รับ 130 คะแนน</h4>
            <p>
              กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์<br />
              กลิ่นวานิลลา 850 กรัม 
              
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/glucerna/new/gl8.png" alt="PDC" />
            </figure>
            <h4>รับ 180 คะแนน</h4>
            <p>
              กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์<br />
              กลิ่นวานิลลา 1,200 กรัม 
              
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="tabId === 'pediasure-tab'" class="mb-3">
      <div class="homeCarousel">
        <ul class="mt-4 pediasure">
          <li>
            <figure>
              <img src="../../assets/images/ped1_new.png" alt="PDC" />
            </figure>
            <h4>รับ 40 คะแนน</h4>
            <p>
              พีเดียชัวร์+ รสจืด<br />
              370 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/pdc_van_370g.png" alt="PDC" />
            </figure>
            <h4>รับ 40 คะแนน</h4>
            <p>
              พีเดียชัวร์ 1+ คอมพลีท <br />
              วานิลลา 370 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/ped2_new.png" alt="PDC" />
            </figure>
            <h4>รับ 80 คะแนน</h4>
            <p>
              พีเดียชัวร์+ รสจืด<br />
              740 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/pdc_van_740g.png" alt="PDC" />
            </figure>
            <h4>รับ 80 คะแนน</h4>
            <p>
              พีเดียชัวร์ 1+ คอมพลีท <br />
              วานิลลา 740 กรัม
            </p>
          </li>

          <li>
            <figure>
              <img src="../../assets/images/pdc3_van_740g.png" alt="PDC" />
            </figure>
            <h4>รับ 90 คะแนน</h4>
            <p>
              พีเดียชัวร์ 3+ คอมพลีท พลัส ดีเอชเอ <br />
              วานิลลา 740 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/ped1-new-img.png" alt="PDC" />
            </figure>
            <h4>รับ 160 คะแนน</h4>
            <p>
              พีเดียชัวร์+ รสจืด<br />
              1480 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/pdc_van_1480g.png" alt="PDC" />
            </figure>
            <h4>รับ 160 คะแนน</h4>
            <p>
              พีเดียชัวร์ 1+ คอมพลีท <br />
              วานิลลา 1480 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/PDS3+_1480_Vanilla.png" alt="PDC" />
            </figure>
            <h4>รับ 180 คะแนน</h4>
            <p>
              พีเดียชัวร์ 3+ คอมพลีท พลัส ดีเอชเอ <br />
              วานิลลา 1480 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/PDS1+_2220_Vanilla.png" alt="PDC" />
            </figure>
            <h4>รับ 320 คะแนน</h4>
            <p>
              พีเดียชัวร์ 1+ คอมพลีท <br />
              วานิลลา 2220 กรัม
            </p>
          </li>
          <li>
            <figure>
              <img src="../../assets/images/pdc3_van_2220g.png" alt="PDC" />
            </figure>
            <h4>รับ 360 คะแนน</h4>
            <p>
              พีเดียชัวร์ 3+ คอมพลีท พลัส ดีเอชเอ <br />
              วานิลลา 2220 กรัม
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-footer [hidden]="showScanner"></app-footer>

<!-- <div [hidden]="!showScanner">
  <div class="video-row" fxFlex="100">
    <video class="video" #video autoplay playsinline></video>
  </div>
  <div fxFlex="100" style="display: none">
    <canvas class="video" id="canvas" #canvas></canvas>
  </div>
  <button class="cameraBtn" (click)="closeScanner()">back</button>
</div> -->

<!-- <div [hidden]="!showScanner">
  <section id="demo-content">
    <scandit-barcode-picker
      #barcodePicker
      style="border-radius: 15% !important"
      id="barcode-picker"
      scanningPaused="true"
      visible="false"
      playSoundOnScan="false"
      vibrateOnScan="true"
      scanSettings.enabledSymbologies='["QR"]'
      scanSettings.codeDuplicateFilter="-1"
      (ready)="onReady()"
      (scan)="onScan($event)"
      (scanError)="onScanError($event)"
    >
    </scandit-barcode-picker>
     <div>
      <video id="video" width="100%" height="100%"></video>
    </div> 
    <button class="cameraBtn" (click)="closeScanner()">back</button>
    <label>Result:</label>
    <pre><code id="result"></code></pre> 
  </section>
</div> -->

<!-- success scan modal -->
<div
  bsModal
  #successScanModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeSuccessScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>{{ "SUCCESS_SCAN_MODAL.SCANNED_SUCCESSFULLY" | translate }}</h4>
        <p>
          {{ "SUCCESS_SCAN_MODAL.CONGRATULATIONS_YOU_RECEIVED" | translate }}
          {{ isprintPoint }} {{ "SUCCESS_SCAN_MODAL.SCORE" | translate }}
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeSuccessScanModal()"
        >
          {{ "SUCCESS_SCAN_MODAL.OK" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- GPAS Success Modal -->
<div
  bsModal
  #gpasSuccessMdl="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeGpasSuccessScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button>
        <h4>สแกนสำเร็จ</h4>
        <p>
          คุณจะได้รับ {{ gpasPoints }} คะแนน <br />
          ระบบจะตรวจสอบและอัปเดตคะแนนภายใน 7 วัน <br />
          กรุณาเก็บใบเสร็จเพื่อเป็นหลักฐาน
        </p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeGpasSuccessScanModal()"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>

<!-- failed scan modal -->
<div
  bsModal
  #failedScanModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeFailedScanModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button> -->
        <h4>{{ "FAILED_SCAN_MODAL.SCAN_FAILED" | translate }}</h4>
        <p>{{ "FAILED_SCAN_MODAL.QR_CODE_ALREADY_IN_USE" | translate }}</p>
        <button
          class="btn btn-block goldenBtn"
          (click)="closeFailedScanModal()"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Announcement modal -->
<div
  bsModal
  #announcementnModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeAnnouncementMdl()"
      >
        <img src="../../../assets/images/close.png" />
      </button>
       <h4>ประกาศ</h4>
       <p>เรียนสมาชิก Abbott MyFamilyClub ทางบริษัท่จะดำเนินการอัพเดทระบบความปลอดภัยของ ระบบสะสมคะแนน ในวันที่ 28/03/2024</p>
       <p>ซึ่งท่านจำเป็นต้องเปลี่ยนรหัสผ่าน เพื่อเข้าใช้งานระบบสะสมคะแนนใหม่ หลังจากวันที่ 28/03/2024 เพื่อกลับเข้าใช้งานใหม่อีกครั้ง</p>
       <p>ขออภัยสำหรับความไม่สะดวกที่อาจเกิดขึ้นระหว่างช่วงเวลาดังกล่าว หากท่านพบปัญหาและต้องการความช่วยเหลือสามารถติดต่อได้ที่ Abbott Cares 02-252-2448</p>
      </div>
    </div>
  </div>
</div>

<!-- exceed limit modal -->
<div
  bsModal
  #exceedLimitModal="bs-modal"
  class="modal fade customModalContainer successScanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeExceedLimitModal()"
        >
          <img src="../../../assets/images/close.png" />
        </button> -->
        <h4>ขออภัย</h4>
        <p
          *ngIf="
            catCode == 'Pediasure' ||
            catCode == 'PEDIASURE' ||
            catCode == 'pediasure'
          "
        >
          คุณสแกนผลิตภัณฑ์พีเดียชัวร์ ครบจำนวน 5 ครั้งสำหรับเดือนนี้แล้ว
          กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่ 02-252-2448
        </p>
        <p
          *ngIf="
            catCode == 'Ensure' || catCode == 'ENSURE' || catCode == 'ensure'
          "
        >
          คุณสแกนผลิตภัณฑ์เอนชัวร์ ครบจำนวน 8 ครั้ง สำหรับเดือนนี้แล้ว
          กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่ 02-252-2448
        </p>
        <p
          *ngIf="
            catCode == 'Glucerna' ||
            catCode == 'GLUCERNA' ||
            catCode == 'glucerna'
          "
        >
          คุณสแกนผลิตภัณฑ์กลูเซอนา เอสอาร์ ทริปเปิ้ลแคร์ ครบจำนวน 5 ครั้ง
          สำหรับเดือนนี้แล้ว กรุณาทำรายการใหม่อีกครั้งเดือนหน้า หรือสอบถามที่
          02-252-2448
        </p>

        <button
          class="btn btn-block goldenBtn"
          (click)="closeExceedLimitModal()"
        >
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>

<!-- pop after click to scan to collect -->
<div
  bsModal
  #popupModalOne="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <section class="text-center">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="onClickScan()"
          >
            <img src="../../../assets/images/close.png" />
          </button>
          <h5 class="primaryBlue mt25 primaryTitle">วิธีการสแกน QR Code</h5>
          <h5 class="secondaryBlue secondaryTitle mt30">
            1. ผลิตภัณฑ์แบบกล่องสแกน QR Code <br />
            ที่บนฝากล่องด้านใน
          </h5>
          <!-- <h5 class="secondaryBluee mt30">{{ 'FORGOT_MODAL1.TITLE3' | translate }}</h5> -->
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="mt-2">
                <img class="qrimg" src="../../../assets/images/qr1.png" />
              </div>
            </div>
          </div>
          <h5 class="secondaryBlue secondaryTitle mt30">
            2. สแกน QR Code ที่ใต้กระป๋อง<br />
            เพื่อสะสมคะแนน
          </h5>
          <!-- <h5 class="secondaryBluee mt30">{{ 'FORGOT_MODAL1.TITLE3' | translate }}</h5> -->
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="mt-2">
                <img
                  class="qrimg"
                  src="../../../assets/images/popupqrcode.png"
                />
              </div>
            </div>
          </div>
          <div class="d-flex flex-row bd-highlight mt-3">
            <div class="p-2 bd-highlight">
              <input
                type="checkbox"
                class="p-2 bd-highlight"
                [(ngModel)]="isNotChecked"
                (change)="
                  checkValue(isNotChecked ? 'isChecked' : 'isNotChecked')
                "
              />
            </div>
            <div class="p-2 bd-highlight">
              <span class="p-1 bd-highlight">ไม่ต้องแสดงหน้าต่างนี้อีก</span>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-block goldenBtn mt-3 mb10"
            (click)="onClickScan()"
          >
            เริ่มสแกน
          </button>
        </section>
      </div>
    </div>
  </div>
</div>
