import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  registerOtpForm: FormGroup;
  submitted = false;
  submitRegOtpForm = false;
  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;
  formValues: any;
  addUserRegisterForm: any = {};
  params: any;
  prodCategory: any;
  scannedQRCode: any;
  timeLeft: any;
  showResendOtp: boolean = false;
  pointsEarned: any;
  entermobNo: any;
  storedMobNum: any;
  entermobNowithzero: any;
  capturedFormData: any;
  patchTermData: any;
  withzeroMob: any;
  refName: any;
  refNo: any;
  regMobNo: any;
  newReg: boolean = false;
  sfCode: any;
  iSprintData: any;
  image: any;
  customerName: any;
  suspiciousCount: any;
  latitude: any;
  longitude: any;
  confidence: any;
  prodImage: any;
  customerData: any;
  isprintPoint: any;
  gpasPoints: any;
  gpasSku: any;
  skipTrans: any;
  @ViewChild('regOtpModal', { static: true }) regOtpModal: ModalDirective;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @ViewChild('successScanModal', { static: true })
  successScanModal: ModalDirective;
  @ViewChild('gpasSuccessMdl', { static: true })
  gpasSuccessMdl: ModalDirective;
  @ViewChild('failedScanModal', { static: true })
  failedScanModal: ModalDirective;
  @ViewChild('exceedLimitModal', { static: true })
  exceedLimitModal: ModalDirective;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: false,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };
  checkQRCode2: any;
  beginTimer: boolean = false;
  emailExsistError: boolean = false;
  queryParams: any;
  inscan: any;
  catCode: any;
  isprintresp: any;
  prodimage: any;
  gpasresp: any;
  otpvalresp: Object;
  changepassresp: Object;
  addcustresp: Object;
  tokengen: Object;
  otpgenresp: Object;
  isReferralCodeAvailable: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
    private bsService: BugsnagService
  ) {
    this.params = this.route.snapshot.params.id;
    console.log(this.params);
    let refcode = this.route.snapshot.queryParams.code;
   
    refcode = this.isEmpty(refcode)
    console.log(refcode, 'refcode');
    if(refcode){
      this.params = '11'
      console.log(this.params, 'this.params')
    }
    this.queryParams = this.route.snapshot.params.data;
    console.log(this.queryParams, 'this.queryParams');

    if (this.queryParams == 'registersection') {
      this.newReg = true;
      this.regMobNo = sessionStorage.getItem('mobileNo');
      console.log(this.regMobNo, ' this.regMobNo');
    }
  }

  isEmpty(data: any) {
    return (data == '' || data == undefined || data == null) ? false : true
  }

  ngOnInit(): void {
    let scancodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.iSprintData = scancodeData;
    console.log(this.iSprintData, 'this.iSprintData')
    this.prodCategory = scancodeData?.product?.categoryCode;
    this.scannedQRCode = sessionStorage.getItem('scanCode');
    this.gpasSku = this.iSprintData?.sku
    console.log(this.gpasSku, 'this.gpasSku ')
    console.log('this scan code', this.scannedQRCode);
    this.skipTrans = sessionStorage.getItem('skipTrans');
    console.log(this.skipTrans, ' this.skipTrans')
    this.inscan = sessionStorage.getItem('inscan');
    console.log(this.inscan, ' this.inscan')

    this.catCode = sessionStorage.getItem('catCode')

    this.checkQRCode2 = scancodeData?.uaid_info_type;
    console.log(this.checkQRCode2, 'this.checkQRCode2======')
    // if(this.checkQRCode2 == 1){
    //   this.router.navigate(['/home'])
    //   return
    // }
    if (this.checkQRCode2 == 2 || this.inscan == 'inscan') {
      this.getPosition();
    }
    if (!this.newReg) {
      console.log('reg form control 1 ======');
      this.registerForm = this.formBuilder.group(
        {
          name: ['', Validators.required],
          surname: ['', Validators.required],
          mobileNumber: [
            '',
            [
              Validators.required,
              Validators.pattern('^((\\+63-?)|0)?[0-9]{9,10}$'),
            ],
          ],
          // email: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
          email: [
            '',
            [
              Validators.pattern(
                '^[a-zA-Z0-9\\W_]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
              ),
            ],
          ],
          password: ['', Validators.required],
          confirmPassword: ['', Validators.required],
          referrerCode: [''],
          acceptTerms: [false, Validators.requiredTrue],
          agreePolicy: [false, Validators.requiredTrue],
        },
        {
          validator: MustMatch('password', 'confirmPassword'),
        }
      );
    } else {
      console.log('reg form control 2 ======');
      this.registerForm = this.formBuilder.group(
        {
          name: ['', Validators.required],
          surname: ['', Validators.required],
          // mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+63-?)|0)?[0-9]{9,10}$")]],
          email: [
            '',
            [
              Validators.pattern(
                '^[a-zA-Z0-9\\W_]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
              ),
            ],
          ],
          // password: ['', Validators.required],
          // confirmPassword: ['', Validators.required],
          referrerCode: [''],
          acceptTerms: [false, Validators.requiredTrue],
          agreePolicy: [false, Validators.requiredTrue],
        }
        // {
        //   validator: MustMatch('password', 'confirmPassword')
        // }
      );
    }

    this.registerOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });

    console.log(this.params, 'this.params--');
    if (this.params == undefined || this.params == '12') {
      let mobile_no = sessionStorage.getItem('mobileNo');
      // this.registerForm.patchValue({
      //   mobileNumber: mobile_no
      // })
      //this.registerForm.controls["mobileNumber"].disable();
    } else {
      // this.registerForm.controls["name"].disable()
      // this.registerForm.controls["surname"].disable()
      // this.registerForm.controls["email"].disable()
    }

    this.apiService.salesforceCheck.subscribe((res) => {
      if (res) {
        let salesForceData = JSON.parse(sessionStorage.getItem('salesForceData'));
        // this.scancodeData = JSON.parse(sessionStorage.getItem('productdata'));
        // this.prodCategory = this.scancodeData?.product?.categoryCode;
        let mobile_no = sessionStorage.getItem('mobileNo');
        this.registerForm.patchValue({
          mobileNumber: salesForceData ? salesForceData?.Phone : '',
          name: salesForceData ? salesForceData?.FirstName : '',
          email: salesForceData ? salesForceData?.Email : '',
          surname: salesForceData ? salesForceData?.LastName : '',
        });
        //this.registerForm.controls["mobileNumber"].disable();
        if (salesForceData?.Email) {
          //this.registerForm.controls["email"].disable();
        }
      } else {
        console.log('data not from salesforce api');
      }
    }, err => {
      this.bsService.notifyError(err);
    });
    this.isReferralCodeAvailable = this.isEmpty(this.route.snapshot.queryParams.code)
    console.log(this.isReferralCodeAvailable)
    if (this.isReferralCodeAvailable) this.registerForm.patchValue(
      { referrerCode: this.route.snapshot.queryParams.code }
    )
    this.patchTermData = sessionStorage.getItem('patchTermsData');
    console.log(this.patchTermData, 'this.patchTermData');
    if (this.patchTermData == 'patchData') {
      this.capturedFormData = JSON.parse(sessionStorage.getItem('capturedValue'));
      console.log(this.capturedFormData, 'this.capturedFormData=========');
      if (this.capturedFormData != undefined) {
        this.registerForm.patchValue({
          mobileNumber: this.capturedFormData
            ? this.capturedFormData?.mobileNumber
            : '',
          name: this.capturedFormData ? this.capturedFormData?.name : '',
          email: this.capturedFormData ? this.capturedFormData?.email : '',
          surname: this.capturedFormData ? this.capturedFormData?.surname : '',
          password: this.capturedFormData
            ? this.capturedFormData?.password
            : '',
          confirmPassword: this.capturedFormData
            ? this.capturedFormData?.confirmPassword
            : '',
        });
      }
    }
  }

  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false;
    this.otp = otp;
    console.log(this.otp, 'this.otp--');
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }
  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  showRegOtpModal() {
    this.ngOtpInputRef.setValue('');
    this.submitRegOtpForm = false;
    this.invalidOtp = false;
    this.activeClassButton = false;
    this.regOtpModal.show();
    this.beginTimer = true;
  }
  closeRegOtpModal() {
    this.submitRegOtpForm = false;
    this.invalidOtp = false;
    this.activeClassButton = false;
    this.ngOtpInputRef.setValue('');
    this.regOtpModal.hide();
    this.beginTimer = false;
  }



  onSubmitRegOtpForm() {
    this.submitRegOtpForm = true;
    if (this.otp == undefined || this.otp.length < 6) {
      return;
    } else {
      console.log('inside else---');
      console.log(this.storRegMobnum, 'storRegMobnum--');
      let formValues = this.registerOtpForm.value;

      this.formValues.mobileNumber = this.storRegMobnum;
      let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
      let otpSubmitData = {
        identifierType: 'MOBILE',
        identifierValue: this.storRegMobnum,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: sessionIdValue,
        otp: this.otp,
      };
      this.spinner.show();
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe(
          (res) => {
            this.otpvalresp = res;
            this.spinner.hide();

            if (res['status'].code == 200) {
              this.regOtpModal.hide();
              sessionStorage.setItem('authToken', res['auth'].token);
              sessionStorage.setItem('authKey', res['auth'].key);
              let token = res['auth'].token;
              let formData = {
                identifierType: 'MOBILE',
                identifierValue: this.storRegMobnum,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                password: 'test123',
                newPassword: this.formValues.password,
                confirmPassword: this.formValues.confirmPassword,
                token: res['auth'].token,
              };
              if (!this.newReg) {
                this.spinner.show();
                this.apiService
                  .changePassword(formData)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                      this.changepassresp = res;
                      if (res['status'].code == 200) {
                        console.log(this.iSprintData, 'this.iSprintData =====')
                        if (this.checkQRCode2 == 1 || this.skipTrans == 'skiptrans') {
                          this.apiService.getPosition().then((pos) => {
                            let lat = pos.lat;
                            let lang = pos.lng;
                            let latitude = JSON.stringify(lat);
                            let longitude = JSON.stringify(lang);
                            let authToken = sessionStorage.getItem('authToken');
                            let authKey = sessionStorage.getItem('authKey');

                            let filledFormValues = this.getuserForm(
                              this.formValues,
                              latitude,
                              longitude
                            );
                            console.log(
                              this.formValues.mobileNumber,
                              'mobile--'
                            );
                            console.log(
                              filledFormValues,
                              'filledFormValues---'
                            );
                            this.apiService
                              .addCapCustomer(
                                filledFormValues,
                                this.formValues.mobileNumber,
                                token
                              )
                              .pipe(first())
                              .subscribe((res) => {
                                this.addcustresp = res;
                                this.spinner.hide();
                                if (res['status'].code == 200) {
                                  console.log(
                                    this.formValues.password,
                                    'this.formValues.password'
                                  );
                                  sessionStorage.setItem(
                                    'userPassword',
                                    this.formValues.password
                                  );
                                  this.router.navigate(['/home',{popup:"true"}]);
                                } else {
                                  console.log(res['status'].message);
                                }
                              }, err => {
                                this.bsService.notifyError(err, filledFormValues);
                                if (err) {
                                  this.spinner.hide();
                                  console.log(err, 'add cust err')
                                }
                              });
                          }, err => {
                            this.bsService.notifyError(err);
                            this.spinner.hide();
                            console.log(err, 'err')
                          });
                        }
                        if (this.checkQRCode2 == 2 || this.inscan == 'inscan') {
                          this.apiService.getPosition().then((pos) => {
                            let lat = pos.lat;
                            let lang = pos.lng;
                            let latitude = JSON.stringify(lat);
                            let longitude = JSON.stringify(lang);

                            // this.apiService.getPosition().then(pos => {
                            //   let lat : any;
                            //   let lang : any;, '
                            //   let latitude;
                            //   let longitude
                            //   if(pos == undefined){
                            //     lat = 'undefined';
                            //     lang = 'undefined';
                            //   }
                            //   else{
                            //   lat = pos.lat != 'undefined' ? pos.lat : 'undefined'
                            //   lang = pos.lng != 'undefined' ? pos.lng : 'undefined'
                            //   latitude = JSON.stringify(lat)
                            //   longitude = JSON.stringify(lang)
                            //   }

                            let authToken = sessionStorage.getItem('authToken');
                            let authKey = sessionStorage.getItem('authKey');

                            let filledFormValues = this.getuserForm(
                              this.formValues,
                              latitude,
                              longitude
                            );
                            console.log(this.formValues.mobileNumber, 'mobile--');
                            console.log(filledFormValues, 'filledFormValues---');
                            this.apiService
                              .addCapCustomer(
                                filledFormValues,
                                this.formValues.mobileNumber,
                                token
                              )
                              .pipe(first())
                              .subscribe(
                                (res) => {
                                  this.customerData =
                                    res['customers']['customer'][0];
                                  this.customerName =
                                    this.customerData.firstname +
                                    ' ' +
                                    this.customerData.lastname;

                                  this.customerData.custom_fields.field.forEach(
                                    (element) => {
                                      if (element.name === 'suspicious_count') {
                                        this.suspiciousCount = parseInt(
                                          element.value
                                        );
                                      }
                                    }
                                  );

                                  console.log(
                                    this.suspiciousCount,
                                    'suspiciousCount'
                                  );
                                  this.spinner.hide();
                                  if (res['status'].code == 200) {
                                    //if(this.scannedQRCode != null || this.scannedQRCode != undefined){
                                   // this.onClickCancelModalone();
                                   sessionStorage.setItem('scanCode',this.scannedQRCode)
                                   sessionStorage.setItem('isQRScanned', 'true');
                                   this.router.navigate(['/home',{popup:"true"}])
                                    // this.apiService.getPosition().then((pos) => {
                                    //   let lat = pos.lat;
                                    //   let lang = pos.lng;
                                    //   let latitude = lat.toString();
                                    //   let longitude = lang.toString();
                                    //   console.log(
                                    //     this.formValues.password,
                                    //     'this.formValues.password'
                                    //   );
                                    //   sessionStorage.setItem(
                                    //     'userPassword',
                                    //     this.formValues.password
                                    //   );

                                    //--- add Transaction----
                                    // let productName =
                                    //   sessionStorage.getItem('productName');
                                    // let sku = sessionStorage.getItem('sku');
                                    // let addformData = {
                                    //   scan_code: this.scannedQRCode,
                                    //   mobile: this.formValues.mobileNumber,
                                    //   cap_device_id: env.DEVICE_ID,
                                    //   custom_fields: [
                                    //     {
                                    //       name: 'translat',
                                    //       value: latitude,
                                    //     },
                                    //     {
                                    //       name: 'translong',
                                    //       value: longitude,
                                    //     },
                                    //     {
                                    //       name: 'productname',
                                    //       value: productName,
                                    //     },
                                    //     {
                                    //       name: 'category_code_trans',
                                    //       value: this.prodCategory
                                    //         ? this.prodCategory
                                    //         : null,
                                    //     },
                                    //     {
                                    //       name: 'sfproductcode',
                                    //       value: sku,
                                    //     },
                                    //   ],
                                    // };
                                    // // if(this.checkQRCode2 == 2){
                                    // this.spinner.show();
                                    // this.apiService
                                    //   .addTransaction(addformData, token)
                                    //   .pipe(first())
                                    //   .subscribe(
                                    //     (res) => {
                                    //       this.spinner.hide();

                                    //       if (res['status'] == 'success') {
                                    //         console.log('in if-----');
                                    //         this.closeRegOtpModal();
                                    //         //Get Points API
                                    //         let mobile =
                                    //           sessionStorage.getItem(
                                    //             'mobileNo'
                                    //           );
                                    //         let token =
                                    //           sessionStorage.getItem(
                                    //             'authToken'
                                    //           );
                                    //         this.spinner.show();
                                    //         this.apiService
                                    //           .getPoints(mobile, token)
                                    //           .pipe(first())
                                    //           .subscribe((res: any) => {
                                    //             this.spinner.hide();
                                    //             console.log(
                                    //               res?.customer
                                    //                 ?.transactions
                                    //                 ?.transaction,
                                    //               'points list'
                                    //             );
                                    //             this.pointsEarned =
                                    //               res?.customer?.transactions?.transaction[0]?.points?.issued;
                                    //             this.onClickSuccessScanModal();
                                    //           });
                                    //       } else {
                                    //         console.log('in else---');
                                    //         this.closeRegOtpModal();
                                    //         this.onClickFailedScanModal();
                                    //       }
                                    //     },
                                    //     (err) => {
                                    //       this.spinner.hide();

                                    //       this.closeRegOtpModal();
                                    //       this.onClickFailedScanModal();
                                    //       console.log(
                                    //         err,
                                    //         '=== get customer error ======='
                                    //       );
                                    //     }
                                    //   );
                                    // }
                                    // });
                                    // }else{
                                    //   this.router.navigate(['/home'])
                                    // }
                                  } else {
                                    console.log(res['status'].message);
                                  }
                                },
                                (err) => {

                                  this.bsService.notifyError(err, filledFormValues);
                                  this.spinner.hide();
                                  console.log(err, 'err')

                                  console.log('add cap customer error');
                                }
                              );

                            // })  end postion api
                          }, err => {
                            this.bsService.notifyError(err);
                            this.spinner.hide();
                            console.log(err, 'err')
                          });
                          // }
                          // else {
                          //   this.apiService.getPosition().then((pos) => {
                          //     let lat = pos.lat;
                          //     let lang = pos.lng;
                          //     let latitude = JSON.stringify(lat);
                          //     let longitude = JSON.stringify(lang);
                          //     let authToken = sessionStorage.getItem('authToken');
                          //     let authKey = sessionStorage.getItem('authKey');

                          //     let filledFormValues = this.getuserForm(
                          //       this.formValues,
                          //       latitude,
                          //       longitude
                          //     );
                          //     console.log(
                          //       this.formValues.mobileNumber,
                          //       'mobile--'
                          //     );
                          //     console.log(
                          //       filledFormValues,
                          //       'filledFormValues---'
                          //     );
                          //     this.apiService
                          //       .addCapCustomer(
                          //         filledFormValues,
                          //         this.formValues.mobileNumber,
                          //         token
                          //       )
                          //       .pipe(first())
                          //       .subscribe((res) => {
                          //         this.spinner.hide();
                          //         if (res['status'].code == 200) {
                          //           console.log(
                          //             this.formValues.password,
                          //             'this.formValues.password'
                          //           );
                          //           sessionStorage.setItem(
                          //             'userPassword',
                          //             this.formValues.password
                          //           );
                          //           this.router.navigate(['/home']);
                          //         } else {
                          //           console.log(res['status'].message);
                          //         }
                          //       });
                          //   });
                          // }
                        } else {
                          this.apiService.getPosition().then((pos) => {
                            let lat = pos.lat;
                            let lang = pos.lng;
                            let latitude = JSON.stringify(lat);
                            let longitude = JSON.stringify(lang);
                            let authToken = sessionStorage.getItem('authToken');
                            let authKey = sessionStorage.getItem('authKey');

                            let filledFormValues = this.getuserForm(
                              this.formValues,
                              latitude,
                              longitude
                            );
                            console.log(
                              this.formValues.mobileNumber,
                              'mobile--'
                            );
                            console.log(
                              filledFormValues,
                              'filledFormValues---'
                            );
                            this.apiService
                              .addCapCustomer(
                                filledFormValues,
                                this.formValues.mobileNumber,
                                token
                              )
                              .pipe(first())
                              .subscribe((res) => {
                                this.spinner.hide();
                                if (res['status'].code == 200) {
                                  console.log(
                                    this.formValues.password,
                                    'this.formValues.password'
                                  );
                                  sessionStorage.setItem(
                                    'userPassword',
                                    this.formValues.password
                                  );
                                  this.router.navigate(['/home',{popup:"true"}]);
                                } else {
                                  console.log(res['status'].message);
                                }
                              }, err=> {
                                this.bsService.notifyError(err, filledFormValues);
                                this.spinner.hide();
                                console.log(err, 'err')
                              });
                          }, err => {
                            this.bsService.notifyError(err, formData);
                            this.spinner.hide();
                            console.log(err, 'err')
                          });
                        }

                      } else {
                        this.spinner.hide()
                        alert("Password is invalid, Please try again")
                        //this.router.navigate(['/home'])
                      }
                    },
                    (err) => {
                      this.bsService.notifyError(err, formData);
                      this.spinner.hide();
                      console.log(err, 'err')

                      console.log('chnage password error');
                    }
                  );
              }
            }
            //---in valid otp
            else {
              this.invalidOtp = true;
            }
          },
          (err) => {
            this.bsService.notifyError(err, otpSubmitData);
            this.spinner.hide();
            console.log(err, 'err')
            console.log('validate api error ---');
          }
        );
    }
    // this.regOtpModal.hide();
  }
  storRegMobnum: any;
  onRegisterFormSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid && !this.newReg) {
      this.formValues = this.registerForm.value;
      console.log(this.formValues, 'this.formValues--invalid');
      // this.showRegOtpModal();
      return;
    } else {
      this.formValues = this.registerForm.value;
      if (this.params == '11' || this.params == '12') {
        this.storRegMobnum = JSON.stringify(this.formValues.mobileNumber);
        //this.formValues.mobileNumber = this.formValues.mobileNumber.replace(/\b0+/g, '');

        console.log(this.storRegMobnum, 'this.formValues.mobileNumber');
        sessionStorage.setItem('mobileNo', this.storRegMobnum);
      }
      // else{
      //   let mobile_no = sessionStorage.getItem('mobileNo');
      //   this.formValues.mobileNumber = this.formValues.mobileNumber;
      // }
      console.log(this.formValues, ' this.formValues--');
      console.log(this.formValues.mobileNumber, 'this.formValues.mobileNumber');
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: this.storRegMobnum,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: 'test123',
        confirmPassword: 'test123',
        //  "password": this.formValues.password,
        //  "confirmPassword": this.formValues.confirmPassword
      };
      this.spinner.show();
      // this.showRegOtpModal();

      //---------on form submit first check in email already exsist--------------
      this.apiService
        .checkExistingEmail(encodeURIComponent(this.formValues.email))
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res.result.statusCode == 200) {
              if (!this.newReg) {
                this.spinner.show();
                if (this.params == '11' || this.params == '12') {
                  this.apiService
                    .tokenGenerate(formData)
                    .pipe(first()) //token generate api
                    .subscribe(
                      (res) => {
                        this.tokengen = res;
                        this.spinner.hide();
                        if (res['status'].code == 200) {
                          let sessionId = res['user'].sessionId;
                          sessionStorage.setItem(
                            'sessionID',
                            JSON.stringify(sessionId)
                          );
                          // if (res['user'].userRegisteredForPassword == true) {
                          //   // if user is present in CRM DB
                          //   console.log('user already registered');
                          //   this.router.navigate(['/login']);
                          // } else {
                            let otpGenerateData = {
                              identifierType: 'MOBILE',
                              identifierValue: this.storRegMobnum,
                              deviceId: env.DEVICE_ID,
                              brand: env.BRAND,
                              sessionId: sessionId,
                            };
                            this.spinner.show();
                            this.apiService
                              .otpGenerate(otpGenerateData)
                              .pipe(first()) // otp generate api
                              .subscribe(
                                (res) => {
                                  this.otpgenresp = res;
                                  this.spinner.hide();
                                  if (res['status'].code == 200) {
                                    this.showRegOtpModal();
                                  } else {
                                    console.log(res['status'].message);
                                  }
                                },
                                (err) => {

                                  this.bsService.notifyError(err, otpGenerateData);
                                  this.spinner.hide();
                                  console.log(err, 'err')

                                  //err in otp generate
                                  this.spinner.hide();
                                  console.log(
                                    err,
                                    '=== OTP gererate error list ======='
                                  );
                                }
                              );
                         // }
                        }
                      },
                      (err) => {
                        // err in token generate
                     
                          this.bsService.notifyError(err, formData);
                          this.spinner.hide();
                          console.log(err, 'err')
                        
                        this.spinner.hide();
                        console.log(err, '=== token generate err=======');
                      }
                    );
                } else {
                  let sessionIdValue = JSON.parse(
                    sessionStorage.getItem('sessionID')
                  );
                  let otpGenerateData = {
                    identifierType: 'MOBILE',
                    identifierValue: this.storRegMobnum,
                    deviceId: env.DEVICE_ID,
                    brand: env.BRAND,
                    sessionId: sessionIdValue,
                  };
                  this.spinner.show();
                  this.apiService
                    .otpGenerate(otpGenerateData)
                    .pipe(first()) // otp generate api
                    .subscribe(
                      (res) => {
                        this.spinner.hide();
                        if (res['status'].code == 200) {
                          this.showRegOtpModal();
                        } else {
                          console.log(res['status'].message);
                        }
                      },
                      (err) => {
                        //err in otp generate
                        this.bsService.notifyError(err);
                        this.spinner.hide();
                        console.log(err, '=== OTP gererate error list =======');
                      }
                    );
                }
              } else if (this.newReg) {
                if (this.checkQRCode2 == 1 || this.skipTrans == 'skiptrans') {
                  this.apiService.getPosition().then((pos) => {
                    let lat = pos.lat;
                    let lang = pos.lng;
                    let latitude = JSON.stringify(lat);
                    let longitude = JSON.stringify(lang);
                    let authToken = sessionStorage.getItem('authToken');
                    let authKey = sessionStorage.getItem('authKey');

                    let filledFormValues = this.getuserForm(
                      this.formValues,
                      latitude,
                      longitude
                    );
                    console.log(
                      this.formValues.mobileNumber,
                      'mobile--'
                    );
                    console.log(
                      filledFormValues,
                      'filledFormValues---'
                    );
                    this.apiService
                      .addCapCustomer(
                        filledFormValues,
                        this.formValues.mobileNumber,
                        authToken
                      )
                      .pipe(first())
                      .subscribe((res) => {
                        this.spinner.hide();
                        if (res['status'].code == 200) {
                          console.log(
                            this.formValues.password,
                            'this.formValues.password'
                          );
                          sessionStorage.setItem(
                            'userPassword',
                            this.formValues.password
                          );
                          this.router.navigate(['/home',{popup:"true"}]);
                        } else {
                          console.log(res['status'].message);
                        }
                      }, err => {
                        if (err) {

                          this.bsService.notifyError(err);
                          this.spinner.hide();
                          console.log(err, 'err')

                          this.spinner.hide();
                          console.log(err, 'add cust err')
                        }
                      });
                  }, err => {
                    this.bsService.notifyError(err);
                    this.spinner.hide();
                    console.log(err, 'err')
                  });
                }
                if (this.checkQRCode2 == 2 || this.inscan == 'inscan') {
                  this.apiService.getPosition().then((pos) => {
                    let lat = pos.lat;
                    let lang = pos.lng;
                    let latitude = JSON.stringify(lat);
                    let longitude = JSON.stringify(lang);

                    // this.apiService.getPosition().then(pos => {
                    //   let lat : any;
                    //   let lang : any;
                    //   let latitude;
                    //   let longitude
                    //   if(pos == undefined){
                    //     lat = 'undefined';
                    //     lang = 'undefined';
                    //   }
                    //   else{
                    //   lat = pos.lat != 'undefined' ? pos.lat : 'undefined'
                    //   lang = pos.lng != 'undefined' ? pos.lng : 'undefined'
                    //   latitude = JSON.stringify(lat)
                    //   longitude = JSON.stringify(lang)
                    //   }

                    let token = sessionStorage.getItem('authTokenNew');
                    let authKey = sessionStorage.getItem('authKey');

                    let filledFormValues = this.getuserForm(
                      this.formValues,
                      latitude,
                      longitude
                    );
                    console.log(this.formValues.mobileNumber, 'mobile--');
                    console.log(filledFormValues, 'filledFormValues---');
                    this.apiService
                      .addCapCustomer(filledFormValues, this.regMobNo, token)
                      .pipe(first())
                      .subscribe(
                        (res) => {
                          this.spinner.hide();

                          if (res['status'].code == 200) {
                            sessionStorage.setItem('mobileNo', this.regMobNo);
                            sessionStorage.setItem('scanCode',this.scannedQRCode)
                            sessionStorage.setItem('isQRScanned', 'true');
                            this.router.navigate(['/home',{popup:"true"}])
                            //this.onClickCancelModalone()
                            // this.apiService.getPosition().then((pos) => {
                            //   let lat = pos.lat;
                            //   let lang = pos.lng;
                            //   let latitude = lat.toString();
                            //   let longitude = lang.toString();
                            //   console.log(
                            //     this.formValues.password,
                            //     'this.formValues.password'
                            //   );
                            //   sessionStorage.setItem(
                            //     'userPassword',
                            //     this.formValues.password
                            //   );
                            //   //--- add Transaction----
                            //   let productName =
                            //     sessionStorage.getItem('productName');
                            //   let sku = sessionStorage.getItem('sku');
                            //   let addformData = {
                            //     scan_code: this.scannedQRCode,
                            //     mobile: this.regMobNo,
                            //     cap_device_id: env.DEVICE_ID,
                            //     custom_fields: [
                            //       {
                            //         name: 'translat',
                            //         value: latitude,
                            //       },
                            //       {
                            //         name: 'translong',
                            //         value: longitude,
                            //       },
                            //       {
                            //         name: 'productname',
                            //         value: productName,
                            //       },
                            //       {
                            //         name: 'category_code_trans',
                            //         value: this.prodCategory
                            //           ? this.prodCategory
                            //           : null,
                            //       },
                            //       {
                            //         name: 'sfproductcode',
                            //         value: sku,
                            //       },
                            //     ],
                            //   };
                            //   // if(this.checkQRCode2 == 2){
                            //   this.spinner.show();
                            //   this.apiService
                            //     .addTransaction(addformData, token)
                            //     .pipe(first())
                            //     .subscribe(
                            //       (res) => {
                            //         this.spinner.hide();

                            //         if (res['status'] == 'success') {
                            //           console.log('in if-----');
                            //           this.closeRegOtpModal();
                            //           //Get Points API
                            //           let mobile =
                            //             sessionStorage.getItem('mobileNo');
                            //           let token =
                            //             sessionStorage.getItem('authToken');
                            //           this.spinner.show();
                            //           this.apiService
                            //             .getPoints(mobile, token)
                            //             .pipe(first())
                            //             .subscribe((res: any) => {
                            //               this.spinner.hide();
                            //               console.log(
                            //                 res?.customer?.transactions
                            //                   ?.transaction,
                            //                 'points list'
                            //               );
                            //               this.pointsEarned =
                            //                 res?.customer?.transactions?.transaction[0]?.points?.issued;
                            //               this.onClickSuccessScanModal();
                            //             });
                            //         } else {
                            //           console.log('in else---');
                            //           this.closeRegOtpModal();
                            //           this.onClickFailedScanModal();
                            //         }
                            //       },
                            //       (err) => {
                            //         this.spinner.hide();

                            //         this.closeRegOtpModal();
                            //         this.onClickFailedScanModal();
                            //         console.log(
                            //           err,
                            //           '=== get customer error ======='
                            //         );
                            //       }
                            //     );
                            //   // }
                            // });
                          } else {
                            console.log(res['status'].message);
                          }
                        },
                        (err) => {

                          this.bsService.notifyError(err);
                          this.spinner.hide();
                          console.log(err, 'err')

                          console.log('add cap customer error');
                        }
                      );

                    // })  end postion api
                  }, err => {
                    this.bsService.notifyError(err);
                  });
                } else {
                  this.apiService.getPosition().then((pos) => {
                    let lat = pos.lat;
                    let lang = pos.lng;
                    let latitude = JSON.stringify(lat);
                    let longitude = JSON.stringify(lang);
                    let token = sessionStorage.getItem('authTokenNew');
                    let authKey = sessionStorage.getItem('authKey');

                    let filledFormValues = this.getuserForm(
                      this.formValues,
                      latitude,
                      longitude
                    );
                    console.log(this.formValues.mobileNumber, 'mobile--');
                    console.log(filledFormValues, 'filledFormValues---');
                    
                   
                    this.apiService
                      .addCapCustomer(filledFormValues, this.regMobNo, token)
                      .pipe(first())
                      .subscribe((res) => {
                        this.spinner.hide();
                        if (res['status'].code == 200) {
                          sessionStorage.setItem('mobileNo', this.regMobNo);
                          console.log(
                            this.formValues.password,
                            'this.formValues.password'
                          );
                          sessionStorage.setItem(
                            'userPassword',
                            this.formValues.password
                          );
                          this.router.navigate(['/home',{popup:"true"}]);
                        } else {
                          console.log(res['status'].message);
                        }
                      }, err => {
                        this.bsService.notifyError(err);
                        this.spinner.hide();
                        console.log(err, 'err')
                      });
                  }, err => {
                    this.bsService.notifyError(err);
                    this.spinner.hide();
                    console.log(err, 'err')
                  });
                }
              }
            }
          },
          (err) => {
            this.bsService.notifyError(err);

            // err in email check api
            this.emailExsistError = true; //email arleady exsist
            console.log('email already exsist---');

            this.spinner.hide();
          }
        );
    }
  }

  get regFormVal() {
    return this.registerForm.controls;
  }

  get regOtpFormVal() {
    return this.registerOtpForm.controls;
  }

  getuserForm(userData, latitude, longitude) {
    // let Signupmode = sessionStorage.getItem('SignupMode')
    console.log(userData, 'userdata----');
    return (this.addUserRegisterForm = {
      root: {
        customer: [
          {
            mobile: this.newReg ? this.regMobNo : userData.mobileNumber,
            email: userData.email,
            firstname: userData.name,
            lastname: userData.surname,
            custom_fields: {
              field: [
                {
                  name: 'categorycode',
                  value: this.prodCategory ? this.prodCategory : null,
                },
                {
                  name: 'reglat',
                  value: latitude,
                },
                {
                  name: 'reglong',
                  value: longitude,
                },
                // {
                //   name: 'referrer_code',
                //   value:
                //   userData.referrerCode == ''
                //       ? 'NO REFERRAR CODE'
                //       : "'" + userData.referrerCode + "'",
                // },
                {
                  name: 'child_name_1',
                  value: '',
                },
                {
                  name: 'child_name_two',
                  value: '',
                },
                {
                  name: 'child_name_three',
                  value: '',
                },
                {
                  name: 'child_name_4',
                  value: '',
                },
                {
                  name: 'child_dob_one',
                  value: '',
                },
                {
                  name: 'child_dob_two',
                  value: '',
                },
                {
                  name: 'child_dob_three',
                  value: '',
                },
                {
                  name: 'child_dob_four',
                  value: '',
                },
              ],
            },
            "extended_fields": {
              "field": [
                {
                  name: 'onboarding',
                  value:
                  userData.referrerCode == ''
                      ? 'NO REFERRAR CODE'
                      : userData.referrerCode,
                }
              ]
            },
          },
        ],
      },
    });
  }

  checkSalesForce(ev) {
    console.log(ev.target.value, '000');
    let mobNo = ev.target.value;
    this.withzeroMob = ev.target.value;
    mobNo = mobNo.replace(/\b0+/g, '');
    this.entermobNo = mobNo;
    //this.entermobNo = parseInt(this.entermobNo)
    this.entermobNowithzero = ev.target.value.replace(/\b0+/g, '');
    this.storedMobNum = this.entermobNowithzero;
    sessionStorage.setItem('storedMobNum', this.storedMobNum);
    console.log(this.entermobNo, 'this.entermobNo');
    console.log(this.entermobNowithzero, 'this.entermobNowithzero');
    if (this.params == '11' || this.params == '12') {
      this.spinner.show();
      this.apiService
        .getSalesForceData(mobNo)
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            let salesforceData = res['result'];
            this.registerForm.patchValue({
              mobileNumber: salesforceData ? salesforceData?.Phone : '',
              name: salesforceData ? salesforceData?.FirstName : '',
              email: salesforceData ? salesforceData?.Email : '',
              surname: salesforceData ? salesforceData?.LastName : '',
            });
            if (salesforceData?.Phone) {
              //this.registerForm.controls["mobileNumber"].disable();
            }
            this.registerForm.controls['name'].enable();
            this.registerForm.controls['surname'].enable();
            this.registerForm.controls['email'].enable();
            this.checkUser(mobNo);
          },
          (err) => {

            this.bsService.notifyError(err);
            this.spinner.hide();
            console.log(err, 'err')

            this.spinner.hide();
            console.log(err, 'err--');
            this.registerForm.controls['name'].enable();
            this.registerForm.controls['surname'].enable();
            this.registerForm.controls['email'].enable();
            this.checkUser(mobNo);
          }
        );
    }
  }

  checkUser(mobNo) {
    this.spinner.show();
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: mobNo,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: 'test123',
      confirmPassword: 'test123',
    };
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
          // if (res['user'].userRegisteredForPassword == true) {
          //   // if user is present in CRM DB
          //   console.log('user already registered');
          //   this.router.navigate(['/login']);
          // }
        }
      }, err => {
        this.bsService.notifyError(err);
      });
  }

  onClickSuccessScanModal() {
    this.successScanModal.show();
  }
  closeSuccessScanModal() {
    this.successScanModal.hide();
    this.router.navigate(['/home',{popup:"true"}]);
  }
  closeGpasSuccessScanModal() {
    this.gpasSuccessMdl.hide();
    this.router.navigate(['/home',{popup:"true"}]);
  }
  onClickFailedScanModal() {
    this.failedScanModal.show();
  }
  closeFailedScanModal() {
    this.failedScanModal.hide();
    this.router.navigate(['/home',{popup:"true"}]);
  }
  closeExceedLimitModal() {
    this.exceedLimitModal.hide();
    this.router.navigate(['/home',{popup:"true"}]);
  }

  handleEvent(event) {
    console.log(event.left, 'event');
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  resendOTP() {
    this.spinner.show();
    this.showResendOtp = false;
    let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    //this.tokenReGenerate();
    let otpGenerateData = {
      mobile: JSON.stringify(this.formValues.mobileNumber),
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      sessionId: sessionIdValue,
    };
    this.apiService
      .otpGenerate(otpGenerateData)
      .pipe(first())
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res['status'].code == 200) {
          } else if (res['status'].code == 401) {
            this.apiService.logout();
          } else {
            this.apiService.showToaster(res['status'].message);
          }
        },
        (err) => {

          this.bsService.notifyError(err);
          this.spinner.hide();
          console.log(err, 'err')

          this.spinner.hide();
          console.log(err, '=== OTP gererate error list =======');
        }
      );
  }

  emailCheck(ev) {
    if (ev.target.value == '') {
      this.emailExsistError = false;
    }
  }

  allowOnlyCharacters(ev) {
    let onlycharacters = /^[A-Za-z]+$/;
    if (!onlycharacters.test(ev.key)) {
      ev.preventDefault();
    }
  }

  capture() {
    let storemob = this.registerForm.value;
    storemob.mobileNumber = this.withzeroMob;
    console.log(storemob.mobileNumber, 'mobileNumber');
    sessionStorage.setItem('capturedValue', JSON.stringify(storemob));
    this.capturedFormData = JSON.parse(sessionStorage.getItem('capturedValue'));
    console.log(this.capturedFormData, 'this.capturedFormData');
    sessionStorage.setItem('cmspage', 'gotoCms');
  }

  ngOnDestroy() {
    sessionStorage.removeItem('patchTermsData');
  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  checkReferel(ev) {
    let referelNo = ev.target.value;
    console.log(typeof referelNo, '000');
    console.log(referelNo, '000');
    this.refNo = referelNo;
    this.apiService.getReferrerName(referelNo).subscribe((refData: any) => {
      console.log(refData.data, 'ref data');
      this.refName = refData.data[0]?.referralname;
      console.log(this.refName, 'this.refName');
    }, err => {
      this.bsService.notifyError(err);
    });
  }

  getPosition() {
    this.apiService.getPosition().then((pos) => {
      let myuuid = uuidv4();
      let loggerFormData = {
        unique_id: myuuid,
        message: JSON.stringify(pos),
      };
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
      sessionStorage.setItem('latitude', this.latitude)
      sessionStorage.setItem('longitude', this.longitude)
      sessionStorage.setItem('confidence', this.confidence)
    }).catch(err => {
      this.bsService.notifyError(err);
    });
  }
  
  parseFunction(value) {
    return JSON.parse(value);
  }


  onClickCancelModalone() {
    // this.congratsModalStepOne.hide();
    // this.processingModal.show();

    // this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));

    console.log(this.iSprintData.sku, 'isprint sku')

    // env.sfproductList.filter(data => {
    //   if (data.sku == this.iSprintData.sku) {
    //     this.sfCode = data.sfcode
    //     console.log(data, 'data===')

    //   }
    // });

    let mobile = sessionStorage.getItem('mobileNo');
    let scanCode = sessionStorage.getItem('scanCode');

    let token = sessionStorage.getItem('authToken');
    console.log(this.iSprintData?.is_isprint);
    if (this.iSprintData?.is_isprint) {
      console.log('image: ', this.image);
      let parsedPrimaryValue = this.parseFunction(
        this.iSprintData?.product_name
      ).en_US;
      let prdname = this.iSprintData?.crm_name?.th_TH
      let formData = {
        scan_code: this.scannedQRCode,
        mobile: mobile,
        cap_device_id: env.DEVICE_ID,
        customer_name: this.customerName,
        suspicious_count: this.suspiciousCount,
        product_name: prdname,
        product_image: this.iSprintData?.product_image,
        confidence: this.confidence,
        product_type: this.iSprintData?.category_code,
        // "child_count": "1",
        //"weight": (this.weight == undefined || this.weight == null) ? '0' : this.weight,
        //"weight": this.weight,
        custom_fields: [
          {
            name: 'translat',
            value: this.latitude,
          },
          {
            name: 'translong',
            value: this.longitude,
          },
          {
            name: 'productname',
            value: prdname ? prdname : 'Pediasure',
          },
          {
            "name": 'categorycode',
            "value": prdname ? prdname : 'PediaSure',
          },
          {
            "name": 'sfproductcode',
            "value": this.iSprintData.sf_product_code ? this.iSprintData.sf_product_code : 'Null',
          },
        ],
      };
      console.log(formData, 'form data');
      this.spinner.show();
      this.apiService
        .postGpassEarnPoints(formData, token)
        .pipe(first())
        .subscribe(
          (res: any) => {
            sessionStorage.removeItem('iSprintData')
            this.isprintresp = res;
            this.spinner.hide();
            // this.processingModal.show();
            if (res['status'] == 'success') {
              this.spinner.hide();
              // this.processingModal.hide();
              // this.congratsModalStepTwo.show()
              // this.disabled = true;
              console.log(res, 'resp on isprint earns points....')
              this.isprintPoint = res?.result?.points
              this.successScanModal.show();

              //Get Points API
              // let mobile = sessionStorage.getItem('mobileNo');
              // let token = sessionStorage.getItem('authToken');
              // this.spinner.show();
              // this.apiService
              //   .getPoints(mobile, token)
              //   .pipe(first())
              //   .subscribe((res: any) => {
              //     this.spinner.hide();
              //     console.log(
              //       res?.customer?.transactions?.transaction,
              //       'points list'
              //     );
              //     this.pointsEarned =
              //       res?.customer?.transactions?.transaction[0]?.points?.issued;
              //     // this.onClickSuccessScanModal();
              //     this.router.navigate(['/home'])
              //   });
            } else {
              // add transcation fail show the pop-up
              this.onClickFailedScanModal();
            }
          },
          (err) => {
            sessionStorage.removeItem('iSprintData')
            this.bsService.notifyError(err, formData);
            this.spinner.hide()
            console.log(err);
            this.onClickFailedScanModal();

            if (err.status === 420) {
              this.exceedLimitModal.show();
            }
            //else {
            //   // this.onClickFailedScanModal();
            //   this.router.navigate(['/profile']);
            // }
            // this.disable = true
          }
        );
    } else {
      this.spinner.show();
      // this.apiService.getskumap(this.iSprintData.sku).subscribe((skuData:any)=>{
      // this.spinner.hide();
      // console.log(skuData, 'skudata');
      // this.sfCode = skuData?.data[0]?.sfcode
      // console.log(this.sfCode, 'this.sfCode');
      this.spinner.show();
      this.apiService
        .getGpasProductImage(this.iSprintData?.sku)
        .subscribe((res: any) => {
          this.prodimage = res;
          this.spinner.hide();
          console.log(res.data, 'getGpasProductImage');
          console.log(this.latitude, this.longitude, this.confidence);
          this.prodImage = res.data[0]?.image;
          let formData = {
            scan_code: this.scannedQRCode,
            mobile: mobile,
            customer_name: this.customerName,
            suspicious_count: this.suspiciousCount,
            product_name: this.iSprintData?.thai_product_name,
            product_image: this.prodImage ? this.prodImage : 'null',
            latitude: this.latitude,
            longitude: this.longitude,
            confidence: this.confidence,
            product_type: this.iSprintData?.category_code,
            description: this.iSprintData?.product_description,
            //"child_count": '1',
            //  "weight": (this.weight == undefined || this.weight == null) ? '0' : this.weight,
            //"weight": this.weight,
            custom_fields: [
              {
                name: 'productname',
                value: this.iSprintData?.thai_product_name,
              },
              {
                name: 'categorycode',
                value: this.iSprintData?.brand,
              },
              {
                name: 'sfproductcode',
                value: this.iSprintData?.product_code,
              },
            ],
          };
          console.log(formData, 'gpas form data');
          this.spinner.show();
          this.apiService
            .postGpassEarnPoints(formData, token)
            .pipe(first())
            .subscribe(
              (res: any) => {
                sessionStorage.removeItem('iSprintData')
                this.gpasresp = res;
                this.spinner.hide();
                console.log('res: ', res);
                // this.processingModal.show();


                if (res['status'] == 'success') {
                  this.spinner.hide();
                  // this.processingModal.hide();
                  // this.congratsModalStepTwo.show();
                  // this.disabled = true;
                  // this.disable = true;
                  //this.gpasPoints = 

                  this.apiService.getSkuPoints(this.iSprintData?.sku).subscribe((res: any) => {
                    console.log(res?.data[0]?.points, 'gpaspoints');
                    this.gpasPoints = res?.data[0]?.points;
                  }, err => {
                    this.bsService.notifyError(err);
                  })
                  console.log(res, 'resp on GPAS earns points....')
                  this.gpasSuccessMdl.show();


                  //console.log(res);
                  //Get Points API
                  // let mobile = sessionStorage.getItem('mobileNo');
                  // let token = sessionStorage.getItem('authToken');
                  // this.spinner.show();
                  // this.apiService
                  //   .getPoints(mobile, token)
                  //   .pipe(first())
                  //   .subscribe((res: any) => {
                  //     this.spinner.hide();
                  //     console.log(
                  //       res?.customer?.transactions?.transaction,
                  //       'points list'
                  //     );
                  //     this.pointsEarned =
                  //       res?.customer?.transactions?.transaction[0]?.points?.issued;
                  //    // this.onClickSuccessScanModal();
                  //    this.router.navigate(['/home'])
                  //   });
                } else {
                  // add transcation fail show the pop-up
                  this.onClickFailedScanModal();
                }
              },
              (err) => {
                sessionStorage.removeItem('iSprintData')
                this.bsService.notifyError(err, formData);
                //alert(err.error.message);
                // this.processingModal.hide();
                this.spinner.hide();
                this.onClickFailedScanModal();
                if (err.status == 420) {
                  this.exceedLimitModal.show();
                }
                //else {
                //   alert(err.error.message);
                //   this.router.navigate(['/profile']);
                // }
              }
            );
        }, err => {
          sessionStorage.removeItem('iSprintData')
          this.bsService.notifyError(err);
          this.spinner.hide();
          console.log(err, 'err')
        });
      // }, err=> {
      //   sessionStorage.removeItem('iSprintData')
      //   this.spinner.hide();
      //   console.log(err, 'err')
      // });
    }
  }
}


