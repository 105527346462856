// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URl: "https://abbott-ph-api-dev.spurtreetech.com/api"
  API_URl: 'https://abbott-ph-api-dev.sttarter.com/api',

  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/5481cc6d850acd866a5c0bd1544066a4',
  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  // BRAND: 'ABBOTTDEMO',
  BRAND: 'ABBOTTALLDEMO',
  DEVICE_ID: '1234551231',
  IDENTIFIER_TYPE: 'MOBILE',
  REDIRECT_URI:"https://abbott-th-dev.sttarter.com/login",
  STTARTER_TERMS_CONDITION_ABOUT_FAQ:
    'https://api.sttarter.com/contentsystem/5481cc6d850acd866a5c0bd1544066a4',
    BUZZEEBEE_URL: 'https://abbott.buzzebees-dev.com',
  ACCOUNTID: "1657876634",
  COUNTRY_CODE: 'TH',
  LANGUAGE: '',
  BOT_PROMPT:'aggressive',
  STATE:'12345abcde',
  LIFFID:'1657876634-GpzDonzm',
  LIFF_URL:`https://liff.line.me/1657876634-GpzDonzm`,
  LINE_CONSENT_URL:'https://access.line.me/oauth2/v2.1/authorize?response_type=code',
  PRODUCT_IMAGES:
    'https://api.sttarter.com/contentsystem/5481cc6d850acd866a5c0bd1544066a4',

    scanditKey:"AfZyQmVfRvlIKSgzqzoAZw0OHeoICxgiICiUTh5nPPXfEcMaY0wJn5NVPsgpAeDzu31hitdf7GRRSSN58lNnKhY04IlPYLWcqVGCv4ot8xI4XuEv1VTCOAh0P7//NqCPC0QhsMxhtW2iVF/9Ai4CaoNB61jxLn4VvW2rmsJoKzr5LqcZnwW/gGYNFoNcIz0eASTB/k3GKFBoOOBdo+LDGpKUUaQzPrXzdk3YU/R+Po26XJveyfh93LmB/YC7oT7bPNTNp9qweu2OmR2n6MbvIqSgxYb7Lu3ZcghIu16lqfzo199e8Fwl/Y28yFpk7+J5t7A3zcQa2a/DtTyHXSfWcDlyQARDLSWWhzzXmpfrxFcqVE1BWNOgA72Y7vdzMRj5HyarjoS2QG/C3UlmCHkVD5O+M5d9+EsyonNJyw5IwPycifwYjzAsLgXt2uN3CGXV449GtrcAxQtuknk1SUffDCs22oHER3MvoCS9ljc0LEavuvgPjmQBflQxv4F3TCNqXNjpxtfJ+Ke+T8B23ndiMDUdJchtzJnZWifjl8Q/zV1i7DXXTG7qpOfyNq5t7fqe9OXs/g9q4srv5/KZpN0GvvwgyiJBb5GmBF4Gs3Em2GzRB2gnxSfrFwNeYmX0MMpwzE2nqNf2gtvTrcYyoDb5/n1O3FDCwejqXBR5k+MdB2rdBhoEvnH61ygnqjSm/tWF6hWeaSY8KCcMaENxeb5gP741i89km60XLtfaKclaSvEiC1DFXKsLzLQyuzoPCKJ5E1AQhSdnIjlR6CG6ksS+jjUuy7tv+823NGXu5cmFOGpheVFSmVL4qwuu1Oh9ls4hYNe4Dt7w/McMZskb1rwSCnuiX6Vyv0KAK0EHLaxHh8xCmGaO",

    sfproductList : [
      {
        sku:'100S434233440',
        sfcode:'S434.140FE'
      },
      {
        sku:'100S434233485',
        sfcode:'S434.101FE'
      },
      {
        sku:'100S434233340',
        sfcode:'S434.140FD'
      },
      {
        sku:'100S434233385',
        sfcode:'S434.101FD'
      },
      {
        sku:'100S616233285',
        sfcode:'S616.101F7'
      },
      {
        sku:'100S616233340',
        sfcode:'S616.140F9'
      },
      {
        sku:'100S842233240',
        sfcode:'S842.140F9'
      },
      {
        sku:'100S842233285',
        sfcode:'S842.101F5'
      },

      
    ],
    bugsnag_version: '23092022',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
